<template>
  <div>
    <div class="text-xl font-semibold mb-8 mt-1">Billing</div>
    <p>Need to update the payment details for your subscription?</p>
    <a
      v-if="billingURL"
      class="text-teal-500 cursor-pointer hover:text-teal-400"
      :href="billingURL"
      rel="noopener"
      >Update Payment Details
      <font-awesome-icon :icon="['far', 'arrow-right-long']"
    /></a>
  </div>
</template>

<script>
import http from '@/http';
export default {
  metaInfo: {
    title: 'Billing - Settings'
  },
  data() {
    return {
      billingURL: null
    };
  },
  methods: {
    getBillingUrl: function() {
      http
        .get('/updatepayment')
        .then(response => {
          this.billingURL = response.data.content[0].url;
        })
        .catch(() => {
          throw Error("Couldn't get billing URL");
        });
    }
  },
  created() {
    this.getBillingUrl();
  }
};
</script>

<style lang="css" scoped></style>
